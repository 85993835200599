<template>
  <div>
    <span class="text-center table-edit-icon" @click="addSubCategory()">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      projectID: null,
      companies: [],
      popupActive: false,
      params: null,
      value: null,
      form: {},
    };
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
    this.projectID = this.params.data.project_id;
    // console.log("this.params :", );
  },
  methods: {
    addSubCategory: function() {
      setTimeout(() => {
        eventBus.$emit("refreshTableSubCategoryList");
      }, 1);
      this.$store.dispatch("setCategoryName", this.params.data.category_name);
      // Vue.localStorage.set("CATDET", JSON.stringify(this.form));
      this.$router.push({
        name: "sub-category",
        params: {
          categoryID: this.params.data.category_id,
          projectID: this.params.data.project_id,
        },
      });
    },
  },

  mounted() {},
});
</script>
<style></style>
