<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/master">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <!-- CATEGORY FORMS -->
      <div class="vx-col w-full mb-base">
        <CategoryForm v-if="checkPermission(['category', 'POST'])" />
      </div>
      <!-- CATEGORY LIST -->
      <div class="vx-col w-full">
        <vs-tabs v-if="checkPermission(['category', 'GET'])">
          <vs-tab label="Category List" icon-pack="feather" icon="icon-box">
            <div class="tab-text">
              <!-- CATEGORY LIST -->
              <div>
                <CategoryList />
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Archive List" icon-pack="feather" icon="icon-box">
            <!-- CATEGORY ARCHIVE LIST -->
            <div>
              <CategoryList listType="archive" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryForm from "@/views/apps/masters/category/CategoryForm.vue";
import CategoryList from "@/views/apps/masters/category/CategoryList.vue";
import projectService from "@/services/projectService.js";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      projects: [],
      projects_value: [],
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
    };
  },
  mounted() {
    this.getProjectsList();
    if (this.$store.getters.getProjectID) {
      setTimeout(
        () =>
          eventBus.$emit(
            "refreshTableCategoryList",
            this.$store.getters.getProjectID
          ),
        1
      );
    }
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    getProjectsList: function() {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterCategoryList: function() {
      let id = this.projects_value.project_id;
      this.$store.dispatch("SetProjectID", id);
      setTimeout(() => {
        eventBus.$emit("refreshTableCategoryList", id);
      }, 1);
    },
  },
  components: {
    CategoryForm,
    CategoryList,
  },
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
